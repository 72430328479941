div.menuItem, div.menuItemSelected {
    padding: 12px 20px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: display 0.3s;
    display: block;
}

div.menuItem {
    background: #FFFFFF;
}

div.menuItem:hover {
    background: #F1F2F4;
}

div.menuItemSelected {
    border-left: 4px solid #7F32CD;
    background: #F2EAFA;
}

div.menuItem h4, div.menuItemSelected h4 {
    font-weight: 500;
    font-size: 14px;
}

div.menuItem h4 {
    margin: 0 0 8px;
}

div.menuItemSelected h4 {
    margin: 0 0 8px -4px;
    color: #7F32CD;
}

span.bookingId {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #636C7D;
}

a.bookingLink {
    color:#636C7D;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
}

span.bookingDate {
    color:#636C7D;
    padding-left: 10px;
    font-weight: 400;
    font-size: 12px;
    float: right;
}

span.unreadMessageCount {
    border-radius: 12px;
    background-color: #7F32CD;
    color: white;
    font-size: 12px;
    font-weight: 700;
    width: 24px;
    display: inline-flex;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    float: right;
}

div.menuItem p.lastMessage, div.menuItemSelected p.lastMessage {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    color: #636C7D;
    font-size: 12px;
}

div.menuItem p.lastMessage {
    color: #636C7D;
}

div.menuItemSelected p.lastMessage {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    margin-left: -4px;
    color: #121417;
}