div.channelListHeader {
    font-size: 18px;
    color: #121417;
    padding: 12px 24px 10px 24px;
    font-weight: 700;
    border-bottom: 1px solid #DDDFE4;
    display: flex;
    justify-content: space-between;
    align-items: center
}

div.chatHubComponent { width: 100vw; height: 100vh }

svg.unreadIcon {
    margin-right: 10px
}

div.groupChannel {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 10px;
    height: 800px;
    max-height: 800px;
    overflow-y: hidden
}

div.groupChannelList {
    display: flex;
    flex-direction: column;
    flex: 0 0 23%;
    min-width: 320px;
    border-right: 1px solid #ccc;
    height: 800px;
    max-height: 800px;
    overflow-y: auto;
    position: sticky
}

div.sendbirdProvider {
    display: flex;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}